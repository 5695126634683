import { PrismicImage, PrismicPageDefinition, PrismicRichText } from "./types";
import { PrismicRawResourceData } from "./resources";

export type TypeSupportServicesService = {
  image: PrismicImage;
  title1: string;
  description: string;
};

export type TypeImageGroup = {
  group_image: PrismicImage;
};
export type Typecertifications = {
  certificate: PrismicImage;
};

export type TypeSuportServicesPage = {
  title: string;
  meta_title: string;
  subtitle: PrismicRichText;

  section_title: string;
  section_title1: string;
  section_title2: string;

  section_subtitle: PrismicRichText;
  section_subtitle1: PrismicRichText;

  cta_title: string;
  cta_text: string;
  cta_button_text: string;
  cta_link: PrismicPageDefinition;
  second_cta_button_text: string;
  second_cta_button_link: PrismicPageDefinition;

  lower_text_1: PrismicRichText;
  lower_text_2: PrismicRichText;

  services: TypeSupportServicesService[];
  image_group: TypeImageGroup[];
  certifications: Typecertifications[];

  quotes: Array<{
    author_name: string;
    author_position: string;
    quote: string;
    logo: PrismicImage;
  }>;

  hero_image: PrismicImage;
  customer_stories: Array<{
    case_study: {
      document: {
        data: PrismicRawResourceData;
      };
    };
  }>;

  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
};

export type TypeSuportServicesQueryResult = {
  allPrismicSupportServices: {
    edges: Array<{
      node: { data: TypeSuportServicesPage };
    }>;
  };
};

export const getPage = (
  data: TypeSuportServicesQueryResult
): TypeSuportServicesPage => {
  return data.allPrismicSupportServices.edges[0].node.data;
};
